import React from 'react'
import { Link } from 'gatsby'

const FrazettaMenu = (props) => (
  <div>
  <h2 className="major">Frank Frazetta</h2>
  <ul className="bigList">
  <li className={props.pageName === '2017_frazetta_kickstarter' ? 'current' : ''}><Link to="/clients/frazetta/2017_frazetta_kickstarter">Frazetta: Worlds</Link></li>
  </ul>
  </div>
)

export default FrazettaMenu
