import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import NakaLightbox from '../../../components/NakaLightbox'
import Layout from '../../../components/layout'
import FrazettaMenu from './_menu.js'

const Frazetta2017KickstarterPage = ({ data }) => (
  <Layout bgClass="clients-frazetta">
    <FrazettaMenu pageName="2017_frazetta_kickstarter" />
    <div className="textPageDetails">
      <p>
        In 2017 Nakatomi joined with the Frank Frazetta estate and Kickstarter to turn his iconic paintings into painstakingly reproduced silkscreen art prints. The project, "Frazetta: Worlds" included the prints, pins, custom shirt, as well as an 'Ugly Holiday Sweater'.
      </p>
    </div>
    <div className="gallery-container">
    <NakaLightbox images={data.allFile.edges} delimiter=" -- " />
    </div>
  </Layout>
)

Frazetta2017KickstarterPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Frazetta2017KickstarterPage

export const pageQuery = graphql`
  query Frazetta2017KickstarterPageIndexQuery {
    allFile(
      filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativePath: {regex: "/frazetta/2017_frazetta_kickstarter/"}}
    	sort: {fields: name}
    ) {
      totalCount
      edges {
        node {
          childImageSharp {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyImageSharpSizes
            }
            fluid {
              srcSet
              src
              originalName
              presentationWidth
              presentationHeight
              originalImg
            }
          }
        }
      }
    }
  }
`
